<template>
  <div class="version-page">
    <v-row class="ml-2 mt-4">
      <v-col v-for="(dataItem, index) in dataItems" :key="index + '_dataItems'" cols="12">
        <label>
          <h2>{{ dataItem.title }} <b style>({{ dataItem.dateUpdated }})</b></h2>
        </label>
        <v-divider class="mt-2 mb-2" />
        <div v-html="dataItem.description" />
      </v-col>
      <v-col cols="12">
          <v-pagination
            v-model="params.offset"
            :length="getPageTotal(totalItem, 10)"
          />
        </v-col>
    </v-row>
  </div>
</template>

<script>
  import { get, sync } from 'vuex-pathify'

  export default {
    name: 'Version',
    // Title page
    metaInfo: {
      titleTemplate: 'Version',
    },
    computed: {
      ...get('adminVersion', [
        'dataItems',
        'totalItem',
      ]),
      ...sync('adminVersion', [
        'params',
      ]),
    },
    watch: {
      'params.offset': {
        handler (newVal, oldVal) {
          // when change offset then call api
          this.$store.dispatch('adminVersion/fetchDataVersionList')
        },
        deep: true,
      },
    },
    async created () {
      if (this.params.offset !== 1) {
        this.params.offset = 1
      } else {
        await this.$store.dispatch('adminVersion/fetchDataVersionList')
      }
    },
  }
</script>

<style lang="scss" scoped>

  // full background desktop 100vh
  $fullScreen: 100vh;
  .version-page {
    background-size: cover;
    padding: 15px;
    min-height: $fullScreen;
    background-color: white;
    label {
      h2 {
        color: #0969da;
        b {
          color: black;
        }
      }
    }
  }
</style>
